import React from 'react'
import { useQuery } from 'react-query'
import { path } from 'ramda'
import { QueryVariables } from '@shared/innmaxLib/services'
import { isNil } from '@shared/utils/webHelper'
import { PAGE_SIZE } from '@services/report'

export const useEventPassing = (
  querys: { [key: string]: any },
  options?: QueryVariables
) => {
  const { data, ...others } = useQuery(
    ['/iii/eventPassing/report/eventDatilyCount', querys],
    {
      enabled: !isNil(querys),
      ...options,
    }
  )

  const dataSource = React.useMemo(() => {
    if (isNil(data)) {
      return { totalPages: 0, content: [] }
    }
    const content = path(['data'], data) as any[]
    const len = content.length
    return {
      total: len,
      totalPages: Math.ceil(len / PAGE_SIZE),
      content,
    }
  }, [data, querys])

  return {
    dataSource,
    ...others,
  }
}
