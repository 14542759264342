import env from '@shared/env'

export enum AllDeviceType {
  LIGHT = 'streetlight', //燈桿
  LAMP_CTLER = 'lampCtler', //燈桿上的燈泡
  BOX = 'switchbox',
  TALKIE = 'visualdoorbell',
  CAMERA = 'camera',
  WDETECT = 'waterlevel',
  LOCATION = 'location',
  ATTACHED = 'attached',
  DISPLAYSCREEN = 'displayscreen',
  WEATHERSTATION = 'weatherstation',
  POWERSUPPLIER = 'powersupplier',
  PILE = 'pile',
  AIBOX = 'aibox',
  ACCESSPOINT = 'accesspoint',
  SOSDEVICE = 'sosdevice',
}

export enum DeviceType {
  LIGHT = 'streetlight', //燈桿
  LAMP_CTLER = 'lampCtler', //燈桿上的燈泡
  BOX = 'switchbox',
  TALKIE = 'visualdoorbell',
  CAMERA = 'camera',
  WDETECT = 'waterlevel',
  LOCATION = 'location',
  ATTACHED = 'attached',
  DISPLAYSCREEN = 'displayscreen',
  WEATHERSTATION = 'weatherstation',
  POWERSUPPLIER = 'powersupplier',
  PILE = 'pile',
  AIBOX = 'aibox',
  ACCESSPOINT = 'accesspoint',
  SOSDEVICE = 'sosdevice',
}

export enum SupportedDeviceType {}
env.deviceTypeSupported.map(
  (type: any) =>
    ((SupportedDeviceType as any)[type] = (AllDeviceType as any)[type])
)

export enum CameraType {
  UNKNOWN = 0,
  BULLET = 1,
  BULLET_OVERALL = 2,
  DOME_OVERALL = 3,
}

export enum MediaType {
  UNKNOWN = 0,
  MJPG = 'MJPEG',
  JPG = 2,
  FLV = 'FLV',
  MP4 = 4,
  REPLAY = 5, //錄影回放
  IFRAME = 6,
  HTML = 'HTML',
  RTSP = 'RTSP',
}

export const DeviceTypeId: { [key: number | string]: DeviceType | number } = {
  0: DeviceType.LIGHT, //燈桿
  1: DeviceType.LIGHT, //燈桿上的燈泡
  2: DeviceType.BOX,
  4: DeviceType.CAMERA,
  6: DeviceType.LOCATION,
  7: DeviceType.ATTACHED,
  8: DeviceType.DISPLAYSCREEN,
  9: DeviceType.WEATHERSTATION,
  11: DeviceType.AIBOX,
  [DeviceType.LIGHT]: 0,
  [DeviceType.LAMP_CTLER]: 1,
  [DeviceType.BOX]: 2,
  [DeviceType.CAMERA]: 4,
  [DeviceType.DISPLAYSCREEN]: 8,
  [DeviceType.WEATHERSTATION]: 9,
}

export enum DeviceStatus {
  NORMAL = 0,
  ALARM = 1,
  REPAIR = 2,
}
console.log('[env.isHiddenRepair]', env.isHiddenRepair)
export const DeviceStatusStr: { [key: string | number]: string | undefined } = {
  [DeviceStatus.NORMAL]: 'normal',
  [DeviceStatus.ALARM]: 'alarm',
  ...(env.isHiddenRepair ? {} : { [DeviceStatus.REPAIR]: 'repair' }),
}

export enum DeviceActive {
  DELETE = -1,
  INACTIVE = 0,
  ACTIVE = 1,
}

export const DeviceActiveStr: { [key: string | number]: string } = {
  [DeviceActive.DELETE]: 'delete',
  [DeviceActive.INACTIVE]: 'inactive',
  [DeviceActive.ACTIVE]: 'active',
}

export const DeviceMarkerColor: {
  [key: string]: {
    icon: string
    normal: string
    repair: string
    alarm: string
    low?: string
    off?: string
    selected: string
  }
} = {
  [DeviceType.LIGHT]: {
    icon: `${env.imageUrlPrefix}/marker/${DeviceType.LIGHT}/normal.svg`,
    normal: 'linear-gradient(315deg, #ffc200, #ffeea5)',
    low: 'linear-gradient(315deg, #a32000, #ffc91b)',
    off: 'linear-gradient(315deg, #967305, #000)',
    repair: 'linear-gradient(315deg, #96a6ad, #96a6ad)',
    alarm: 'linear-gradient(315deg, #c50101 1%, #ff1919)',
    selected: 'linear-gradient(0deg, #00deff, #00deff)',
  },
  [DeviceType.CAMERA]: {
    icon: `${env.imageUrlPrefix}/marker/${DeviceType.CAMERA}/normal.svg`,
    normal: 'linear-gradient(315deg, #006f34, #a6ff39)',
    repair: 'linear-gradient(315deg, #96a6ad, #96a6ad)',
    alarm: 'linear-gradient(315deg, #c50101 1%, #ff1919)',
    selected: 'linear-gradient(0deg, #00deff, #00deff)',
  },
  [DeviceType.DISPLAYSCREEN]: {
    icon: `${env.imageUrlPrefix}/marker/${DeviceType.DISPLAYSCREEN}/normal.svg`,
    normal: 'linear-gradient(315deg, #6a5e00, #b4b370)',
    repair: 'linear-gradient(315deg, #96a6ad, #96a6ad)',
    alarm: 'linear-gradient(315deg, #c50101 1%, #ff1919)',
    selected: 'linear-gradient(0deg, #00deff, #00deff)',
  },
  [DeviceType.WEATHERSTATION]: {
    icon: `${env.imageUrlPrefix}/marker/${DeviceType.WEATHERSTATION}/normal.svg`,
    normal: 'linear-gradient(315deg, #12229e, #7da5ff)',
    repair: 'linear-gradient(315deg, #96a6ad, #96a6ad)',
    alarm: 'linear-gradient(315deg, #c50101 1%, #ff1919)',
    selected: 'linear-gradient(0deg, #00deff, #00deff)',
  },
  [DeviceType.SOSDEVICE]: {
    icon: `${env.imageUrlPrefix}/marker/${DeviceType.SOSDEVICE}/normal.svg`,
    normal: 'linear-gradient(315deg, #B96A19, #ba8b5b)',
    repair: 'linear-gradient(315deg, #96a6ad, #96a6ad)',
    alarm: 'linear-gradient(315deg, #c50101 1%, #ff1919)',
    selected: 'linear-gradient(0deg, #00deff, #00deff)',
  },
  [DeviceType.ACCESSPOINT]: {
    icon: `${env.imageUrlPrefix}/marker/${DeviceType.ACCESSPOINT}/normal.svg`,
    normal: 'linear-gradient(315deg, #4E1786, #6d518a)',
    repair: 'linear-gradient(315deg, #96a6ad, #96a6ad)',
    alarm: 'linear-gradient(315deg, #c50101 1%, #ff1919)',
    selected: 'linear-gradient(0deg, #00deff, #00deff)',
  },
}
