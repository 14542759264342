import React from 'react'
import {
  propSatisfies,
  propEq,
  filter,
  pathOr,
  isEmpty,
  forEach,
  propOr,
  keys,
  prop,
  find,
} from 'ramda'
import {
  useMakeMutation,
  useLazyQuery,
  convertData,
  QueryVariables,
  request,
} from '@shared/innmaxLib/services'
import type { MutationOptions } from '@shared/innmaxLib/services'
import { isNil } from '@shared/innmaxLib/utils/webHelper'
import type { IPlace } from '@services/session'
import TreeNode from './TreeNode'
import { useSessionStatistical, useAllSessions, ISession } from '@services/session'
import { useMineInfo } from '@services/auth'
import { usePersistQuery } from '@shared/innmaxLib/services/usePersistQuery'
import Tooltip from '@shared/innmaxUI/Tooltip'
import { DeviceType } from '@services/constants'
import { ISampleDeviceDto } from '@services/device'
import { MapType } from '../constants/map'
const { compose } = require('ramda')

export type QueryProps = {
  isPositioned: boolean
  deviceType?: string
  sessionId?: string
  input?: string
  current?: number
}

export const UNKNOW_ID = 1

export const useDeviceInfos = ({ variables = {}, ...options }: QueryVariables = {}) => {
  const { data, refetch, ...others } = useLazyQuery(
    ['/device/sampleDeviceInfos', { current: '', size: '', isSub: false, ...variables }],
    {
      ...options,
      keepPreviousData: true,
    }
  )

  const dataSource: any = React.useMemo(() => {
    if (!data) {
      return []
    }

    return convertData<ISampleDeviceDto>({ field: ['data'] }, data)
  }, [data])

  return {
    ...others,
    refetch,
    loading: others.isLoading,
    queryDeviceInfos: refetch,
    dataSource: dataSource?.content || [],
  }
}

/**
 *
 * @param active:-1刪除 | 0停用 | 1啟用
 * @param isSub: 是否為附掛裝置
 */
export const useDevicesByDtype = ({ variables = {}, ...options }: QueryVariables = {}) => {
  const { data, refetch, ...others } = useLazyQuery(
    ['/device/{deviceType}/{pageNum}/{pageSize}', { active: 1, isSub: false, ...variables }],
    {
      ...options,
    }
  )

  const dataSource: any = React.useMemo(() => {
    if (!data) {
      return {}
    }

    return convertData<any>({ field: ['data'] }, data)
  }, [data])

  const _refetch = (values: any) => {
    refetch(values)
  }

  return {
    ...others,
    queryDevices: _refetch,
    loading: others.isLoading,
    dataSource: dataSource || {},
  }
}

/**
 * 沒有回傳 childrens
 */
export const useSessionDetailById = ({ variables = {}, ...options }: QueryVariables) => {
  const { data, refetch, ...others } = useLazyQuery(['/sessionInfo/detail', variables.id], {
    ...options,
  })

  const dataSource = React.useMemo(
    () =>
      convertData<IPlace>({
        converter: x => ({
          ...x,
          isPositioned: !isNil(x.x) || !isNil(x.lat),
          hasMap: !isNil(x.layerFilePath),
          isBuilding: true,
        }),
      })(data),
    [data]
  )

  return {
    ...others,
    loading: others.isLoading,
    dataSource: dataSource?.content || [],
  }
}

export const useRootLayer = (options?: QueryVariables) => {
  const [isLoading, setIsLoading] = React.useState(false)

  const { baseLayer } = useSessionTree()

  const sessionId = React.useMemo(() => compose(pathOr(undefined, ['id']))(baseLayer), [baseLayer])

  const { refetch, dataSource: deviceInfos } = useDeviceInfos({
    variables: { isPositioned: true },
    onSuccess: () => {
      setIsLoading(false)
    },
    onError: () => {
      setIsLoading(false)
    },
  })

  const dataSource = React.useMemo(() => {
    return deviceInfos
  }, [deviceInfos])

  React.useEffect(() => {
    if (sessionId) {
      refetch({ sessionId })
    }
  }, [sessionId]) //eslint-disable-line

  React.useEffect(() => {
    setIsLoading(true)
  }, [])

  return {
    loading: isLoading,
    baseLayer: baseLayer || {},
    deviceList: dataSource || [],
  }
}

interface TreeQueryVariables extends QueryVariables {
  baseSessionId?: number
}

/**
 *
 * @param treeAvailable:是否為使用者所屬
 * @param status:-1刪除 | 0停用 | 1啟用 (已過時)
 * @param type 0未分區|1地圖|2節點|3圖資
 *
 */
export const useSessionTree = (options: TreeQueryVariables = {}) => {
  const { getFullSessions, isLoading } = useAllSessions()

  const { dataSource: mineInfo } = useMineInfo()

  const mySessions = pathOr<ISession[]>([], ['sessionInfos'], mineInfo)

  const { baseSessionId, ...otherOptions } = options

  const [baseLayer, setBaseLayer] = React.useState<any>({})
  const [searchResults, setSearchResults] = React.useState<IPlace[]>([])
  const [byId, setById] = React.useState<any>()
  const { data, refetch, ...others } = usePersistQuery(['/sessionInfo/tree/all'], {
    refetchedErrorCodes: [-51, -52, -53, -54, -55, -56, -57, -58],
    ...otherOptions,
  })

  const convertTreeData = React.useCallback((p: any) => {
    if (isEmpty(p.children)) {
      return []
    }

    const treeData: any[] = []

    let cId: any = {}

    compose(
      forEach((c: any) => {
        if (c.status !== -1 && c.treeAvailable) {
          const { sessions, ...others } = c

          const key = `${p.key}-${c.id}`

          const obj = {
            ...others,
            title: c.name,
            key,
            locationNs: p.locationNs,
            children: convertTreeData({
              ...c,
              key,
              locationNs: `${p.locationNs}/${c.name}`,
              children: sessions,
            }),
            isPositioned: !isNil(c.x) || !isNil(c.lat),
            hasPermission: mineInfo?.allSessionPermission || mySessions.filter(s => s.id === c.id).length > 0,
            // isPositioned: !isNil(c.lat),
            hasMap: !isNil(c.layerFilePath),
            hasLayerImage: !isNil(c.layerFilePath),
            isBuilding: true,
            sessionPath: getFullSessions(c.id),
            // deviceType: DeviceType.LOCATION,
          }
          cId[c.id] = obj
          // for LayoutTree component
          obj.payload = obj
          treeData.push(obj)
        }
      }),
      pathOr([], ['children'])
    )(p)

    cId[p.id] = p
    setById((prev: any) => ({ ...prev, ...cId }))
    return treeData
  }, [getFullSessions, mineInfo])

  React.useEffect(() => {
    const rootTree = compose(
      filter(propSatisfies(x => x !== 0, 'type')), // 顯示分區的資料
      filter(propEq('status', 1)),
      filter(propEq('treeAvailable', true))
    )(pathOr([], ['data'], data))

    const Unknow = compose(find(propEq('id', UNKNOW_ID)))(pathOr([], ['data'], data))

    if (Unknow) {
      setById(() => ({ [UNKNOW_ID]: { ...Unknow, name: '未分區' } }))
    }

    if (isNil(rootTree)) {
      setBaseLayer({})
      return
    }

    let rootNode: IPlace[] = []

    rootTree.forEach((p: any) => {
      const { sessions, ...others } = p
      const childs = convertTreeData({
        ...others,
        key: p.id,
        locationNs: p.name,
        children: p.sessions,
      })

      const params = {
        ...others,
        key: p.id,
        isBuilding: true,
        hasMap: !isNil(p.layerFilePath),
        hasPermission: mineInfo?.allSessionPermission || mySessions.filter(s => s.id === p.id).length > 0,
        sessionPath: getFullSessions(p.id),
        children: childs,
        title: p.name,
      }

      rootNode.push(params)
      setById((prev: any) => ({ ...prev, [p.id]: params }))
    })
    let baseNode = rootNode[0]
    if (!!baseSessionId) {
      const matchNode = find<IPlace>(propEq('id', baseSessionId))(rootNode[0].children)
      matchNode && (baseNode = matchNode)
    }
    setBaseLayer(baseNode)
  }, [data, isLoading, mineInfo]) //eslint-disable-line

  const queryBaseLayer = React.useCallback(
    (searchValue: string) => {
      if (isNil(baseLayer)) {
        return
      }

      let searchResults: IPlace[] = []

      const loop = (data: any) => {
        data.forEach((item: IPlace) => {
          const index = item.name.indexOf(searchValue)
          // console.log('index', index)
          if (index > -1) {
            searchResults.push(item)
          }
          if (item.children) {
            loop(item.children)
          }
        })
      }

      loop([baseLayer])

      setSearchResults(searchResults)
    },
    [baseLayer]
  )

  const AvailableSessions = React.useMemo(() => {
    let sessions = []
    if (byId) {
      sessions.push(byId[1])

      if (!isNil(baseLayer)) {
        sessions.push(baseLayer)
        baseLayer.children.forEach((i: any) => sessions.push(i))
      }
    }
    return sessions
  }, [byId, baseLayer])

  return {
    // ...others,
    loading: others.isLoading,
    // refetch: () => data,
    refetch,
    queryBaseLayer,
    baseLayer: baseLayer || {},
    searchResults,
    byId,
    AvailableSessions,
  }
}

export const usePlaces = ({ node }: { node: IPlace }) => {
  const [searchResults, setSearchResults] = React.useState<IPlace[]>([])

  const queryPlaceList = React.useCallback(
    (query: QueryProps) => {
      if (isNil(node)) {
        return
      }

      let searchResults: IPlace[] = []
      const childs = [...node.children]
      childs.forEach((item: IPlace) => {
        const index = item.name.indexOf(query.input || '')

        if (index > -1 && item.isPositioned === query.isPositioned) {
          searchResults.push(item)
        }
      })

      setSearchResults(searchResults)
    },
    [node]
  )

  return {
    searchResults,
    queryPlaceList,
  }
}

export type ModifyPlaceProps = {
  id: number
  lat: number
  lon: number
  zoom: number
}

export const updatePlaces = (values: Partial<ModifyPlaceProps>) =>
  request(`/sessionInfo/list/site`, { method: 'PUT', body: values })

export const useUpdatePlaces = (options: MutationOptions = {}) => {
  return useMakeMutation(updatePlaces, options)
}

export type ModifyDeviceBody = {
  deviceType: string
  id: number
  lat: number
  lon: number
  sessionId: number
}

export const updateDevices = (values: Partial<ModifyDeviceBody>) =>
  request('/device/list/site', { method: 'PUT', body: values })

export const useUpdateDevices = (options: MutationOptions = {}) => {
  return useMakeMutation(updateDevices, options)
}

type DeviceInfoCounts = {
  deviceAlarmCount: number
  deviceCount: number
  deviceNormalCount: number
  deviceRepairCount: number
}

export const useSessionDeviceInfoCounts = (
  sessionId?: number,
  options: {
    enable?: boolean
    deviceType?: string
  } = {}
): {
  deviceCounts: DeviceInfoCounts
  getDeviceCounts: (sessionData: any) => DeviceInfoCounts
} => {
  const { enable = false, deviceType } = options

  const { byId } = useSessionTree()
  const { dataSource } = useSessionStatistical({ deviceType })

  const getDeviceCounts = React.useCallback(
    (sessionData: any): DeviceInfoCounts => {
      const deviceCounts = propOr<DeviceInfoCounts, any, DeviceInfoCounts>(
        {
          deviceAlarmCount: 0,
          deviceCount: 0,
          deviceNormalCount: 0,
          deviceRepairCount: 0,
        },
        sessionData.id,
        dataSource.byId
      )

      if (sessionData?.children.length > 0) {
        sessionData?.children?.forEach((session: any) => {
          const childrenCounts = getDeviceCounts(session)
          keys(deviceCounts).forEach(key => {
            deviceCounts[key] += childrenCounts[key]
          })
        })
      }
      return deviceCounts
    },
    [dataSource.byId]
  )

  const deviceCounts: DeviceInfoCounts = React.useMemo(
    () =>
      sessionId && !!prop(sessionId, byId) && enable
        ? getDeviceCounts(byId[sessionId])
        : {
            deviceAlarmCount: 0,
            deviceCount: 0,
            deviceNormalCount: 0,
            deviceRepairCount: 0,
          },
    [byId, enable, getDeviceCounts, sessionId]
  )

  return {
    deviceCounts,
    getDeviceCounts,
  }
}

function isSessionIdMatched(sessionId: number, session: any, nodes: any[]): boolean {
  if (propEq('id', sessionId)(session)) {
    nodes.push(session)
    return true
  }
  let isMatched = false
  const children: any[] = propOr([], 'children')(session)
  children.forEach(childSession => {
    if (isSessionIdMatched(sessionId, childSession, nodes)) {
      nodes.unshift(session)
      isMatched = true
    }
  })
  return isMatched
}

export const useGetSessionBreadcrumbNodes = (sessionId?: number) => {
  const { baseLayer } = useSessionTree()
  const breadcrumbNodes: any[] = []
  if (!sessionId) {
    return breadcrumbNodes
  }
  isSessionIdMatched(sessionId, baseLayer, breadcrumbNodes)
  return breadcrumbNodes
}
